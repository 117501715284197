class App
	Map: null 
	constructor:(config)->
		if jQuery('#main-map').length > 0
			mapConfig = {}
			if window.currentPlace?
				mapConfig = {
					coords:[window.currentPlace.latitude, window.currentPlace.longitude],
					zoom:13
				}
			@Map = new AtlasMap(mapConfig, window.initialMapData, window.currentPlace)
		$editAddress = jQuery('form.form-edit-add input[name="address"]')
		if $editAddress.length > 0
			jQuery.getScript('https://maps.googleapis.com/maps/api/js?key='+encodeURIComponent(AtlasMap.prototype.config.apiKey)+'&libraries=places&callback=app.createAdminMap')

	
	createAdminMap:()=>
		$editAddress = jQuery('form.form-edit-add input[name="address"]')
		$map = jQuery('<div/>').addClass('edit-address-map')
		$map.insertAfter($editAddress)
		lat = jQuery('form.form-edit-add input[name="latitude"]').val()*1
		lng = jQuery('form.form-edit-add input[name="longitude"]').val()*1


		opts = 
			center:
				lat:lat
				lng:lng
			zoom: 13

		map = new google.maps.Map($map.get(0), opts)
		map.controls[google.maps.ControlPosition.TOP_RIGHT];
		autocomplete = new google.maps.places.Autocomplete($editAddress.get(0));
		infowindow = new google.maps.InfoWindow();
		infowindowContent = document.getElementById('infowindow-content');
		infowindow.setContent(infowindowContent);
		markerOpts = 
			map: map
			anchorPoint: new google.maps.Point(0, -29)
		marker = new google.maps.Marker(markerOpts)

		if lat? && lng? && lat != 0 && lng != 0
			latlng = 
				lat: lat
				lng: lng
			marker.setPosition latlng
			marker.setVisible(true);


		autocomplete.addListener 'place_changed', ()->
			infowindow.close();
			marker.setVisible(false);
			place = autocomplete.getPlace();
			if !place.geometry
				window.alert("No details available for input: '" + place.name + "'");
				return
			if place.geometry.viewport
				map.fitBounds(place.geometry.viewport);
			else
				map.setCenter(place.geometry.location);
				map.setZoom(17);
			marker.setPosition(place.geometry.location);
			jQuery('form.form-edit-add input[name="latitude"]').val(place.geometry.location.lat())
			jQuery('form.form-edit-add input[name="longitude"]').val(place.geometry.location.lng())
			marker.setVisible(true);
			address = '';
			if place.address_components
				address = [
					(place.address_components[0] && place.address_components[0].short_name || ''),
					(place.address_components[1] && place.address_components[1].short_name || ''),
					(place.address_components[2] && place.address_components[2].short_name || '')
				].join(' ');
			infowindowContent.children['place-icon'].src = place.icon;
			infowindowContent.children['place-name'].textContent = place.name;
			infowindowContent.children['place-address'].textContent = address;
			infowindow.open(map, marker);		



jQuery ->
	window.app = new App() 